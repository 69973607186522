import React from 'react';
import styled from 'styled-components';
import Img from "gatsby-image";
import TagsBlock from './tags';

const FooterStyles = styled.footer`
  width: 100%;
  margin: 0 auto 50px auto;
  border-top: ${props => props.theme.dark ? '1px solid var(--light20)' : '1px solid var(--dark20)'};
  display: grid;
  grid-template-columns: 2.3fr 1fr;
  grid-gap: 3%;
  padding: 50px 0 0 0;
  @media (max-width: 825px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 585px) {
    border-top: none;
  }
`;

const AuthorColumn = styled.div`
  display: flex;
  @media (max-width: 425px) {
    display: block;
    text-align: center;
  }
  h1 {
    font-size: 2.4em;
    margin-bottom: 0.4em;
    color: ${props => props.theme.dark ? 'var(--light100)' : 'var(--dark100)'};
  }
  img, .profile {
    min-width: 100px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 20px;
    @media (max-width: 825px) {
      min-width: 70px;
      width: 70px;
      height: 70px;
      margin-right: 15px;
    }
    @media (max-width: 425px) {
      margin-bottom: 20px;
      width: 120px;
      height: 120px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  p {
    font-size: 1.6em;
    line-height: 1.6;
    color: ${props => props.theme.dark ? 'var(--light80)' : 'var(--dark80)'};
    &.copyright {
      text-transform: uppercase;
      font-size: 1.2em;
      margin-top: 0.8em;
      letter-spacing: 1px;
      @media (max-width: 825px) {
        margin-top: 1.5em;
      }
    }
  }
  a {
    color: ${props => props.theme.dark ? 'var(--light80)' : 'var(--dark80)'};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.dark ? 'var(--light100)' : 'var(--dark100)'};
      text-decoration: none;
      transition: all ease-in-out 0.2s;
    }
  }
`;

const PostsByTopic = styled.div`
  @media (max-width: 825px) {
    margin: 15px 0 0 85px;
  }
  @media (max-width: 425px) {
    margin: 15px 0 0 0;
    text-align: center;
  }
  h1 {
    font-size: 2.4em;
    margin-bottom: 0.4em;
    color: ${props => props.theme.dark ? 'var(--light100)' : 'var(--dark100)'};
    @media (max-width: 425px) {
      margin-bottom: 1em;
    }
  }
  p {
    font-size: 1.6em;
    color: ${props => props.theme.dark ? 'var(--light80)' : 'var(--dark80)'};
  }
  ul {
    font-size: 1.4em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style-type: none;
    text-transform: capitalize;
  }
  li {
    margin-bottom: 0.4em;
    white-space: nowrap;
    margin-right: 15px;
    a {
      border: none;
      color: ${props => props.theme.dark ? 'var(--light80)' : 'var(--dark80)'};
      &:hover {
        color: ${props => props.theme.dark ? 'var(--light100)' : 'var(--dark100)'};
        transition: all ease-in-out 0.2s;
      }
    }
  }
`;

class BlogFooter extends React.Component {
  render() {
    return (
      <>
        <FooterStyles>
          <AuthorColumn>
            <Img className="profile" sizes={this.props.chrissy.childImageSharp.sizes} alt="Photo of Chrissy Hunt" />
            <div>
              <h1>About Me</h1>
              <p>I'm <a href="https://chrissyhunt.com">Chrissy Hunt</a>, a rare books dealer turned software engineer in NYC. I share photographs and personal reflections here, and blog about technology <a href="https://chrissyhunt.com">over here</a>.</p>
              <p className="copyright">All content copyright &copy; { new Date().getFullYear() } Chrissy Hunt.</p>
            </div>
          </AuthorColumn>
          <PostsByTopic>
            <h1>Browse by Topic</h1>
            <TagsBlock group={this.props.group}/>
          </PostsByTopic>
        </FooterStyles>
      </>
    )
  }
}

export default BlogFooter;