import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components';
import Img from 'gatsby-image';
import BlogFooter from '../components/blog-footer';
import { LocationIcon, CameraIcon } from '../components/svgs';

const PostsLayout = styled.div`
  width: 100%;
`;

function setImageGridCols(props) {
  if (props.format === 'portrait' && props.prevNodeFlipped) return '1 / 2';
  if (props.format === 'portrait') return '2 / 3';
  return '1 / 3';
}

function setTextGridCols(props) {
  if (props.format === 'portrait' && props.prevNodeFlipped) return '2 / 3';
  if (props.format === 'portrait') return '1 / 2';
  return '1 / 3';
}

// function setTextMargins(props) {
//   if (props.format === 'portrait' && props.flipped) return ''
// }

export const PostContainer = styled.div`
  margin-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 825px) {
    grid-template-columns: 1fr;
  }
  .image-container {
    grid-column: ${props => setImageGridCols(props)};
    @media (max-width: 825px) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }
  .gatsby-image-wrapper {
    img {
      max-height: 100%;
      height: 100%;
      width: auto !important;
      object-fit: contain !important;
      object-position: top left !important;
      @media (max-width: 825px) {
        max-height: 100vh;
      }
    }
  }
  h1 {
    font-size: 2.4em;
    margin-top: 1em;
    margin-bottom: 0.25em;
    font-weight: normal;
    color: var(--light80) !important;
    @media (min-width: 1200px){
      font-size: 3em;
    }
  }
  .secondary {
    display: ${props => props.format === 'portrait' ? 'grid' : 'flex'};
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: 15px auto;
    grid-column-gap: 15px;
    grid-row-gap: 0.8em;
    text-transform: uppercase;
    font-size: 1.4em;
    line-height: 0.8em;
    color: var(--light60) !important;
    letter-spacing: 1px;
    margin-bottom: 1em;

    @media (max-width: 825px) {
      display: flex;
    }
  }
  .summary {
    font-family: 'Crimson Text';
    font-size: 1.8em;
    color: var(--light80) !important;
    line-height: 1.2;
  }
  .text {
    grid-column: ${props => setTextGridCols(props)};
    text-align: ${props => props.flipped ? 'right' : 'left'};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin: ${props => props.format === 'portrait' ? '10% 10% 40% 10%' : '0 0 0 0'};
    @media (max-width: 825px) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      margin: 0;
    }
  }
  .icon-container {
    width: 15px;
    margin-left: ${props => props.format === 'portrait' ? '0' : '10px'};
    margin-right: 6px;
    &.first {
      margin-left: 0;
    }
    @media (max-width: 825px) {
      margin-left: 10px;
    }
  }
`;

export const ArchiveLinkFooter = styled.div`
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
  a {
    border: none;
  }
`;

export const ArchiveButton = styled.button`
  font-size: 1.4em;
  background-color: transparent;
  color: ${props => props.theme.dark ? 'var(--light80)' : 'var(--dark80)'};
  padding: 1.2em 1.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid transparent;
  &:hover {
    border: ${props => props.theme.dark ? '1px solid var(--light60)' : '1px solid var(--dark60)'};
    transition: border ease-in-out 0.2s;
  }
  &:active {
    outline: none;
  }
`;


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const group = data.allMarkdownRemark.group
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `photography`, `photos`, `react`, `gatsby`, `nyc`, `new york city`, `brooklyn`]}
        />
        <PostsLayout>
          {posts.map((post, index) => {
            const { node } = post;
            const title = node.frontmatter.title || node.fields.slug;
            const { aspectRatio } = node.frontmatter.featuredImage.childImageSharp.fluid;
            const prevNode = index > 0 && posts[index-1].node;
            node.flipped = !prevNode.flipped && aspectRatio < 1;
            const text = (
              <div
                className="text"
                aspectRatio={node.frontmatter.featuredImage.childImageSharp.fluid.aspectRatio}
                format={node.frontmatter.featuredImage.childImageSharp.fluid.aspectRatio >= 1 ? 'landscape' : 'portrait'}>
                <h1>{title}</h1>
                <div className="secondary">
                  <div className="icon-container first"><LocationIcon fill={ 'var(--light60)' } /></div>
                  { node.frontmatter.location }
                  <div className="icon-container"><CameraIcon fill={ 'var(--light60)' } /></div>
                  { node.frontmatter.photoDate }
                </div>
                <p className="summary" dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </div>
            );
            return (
              <PostContainer key={node.fields.slug}
                prevNodeFlipped={prevNode && prevNode.flipped}
                format={aspectRatio >= 1 ? 'landscape' : 'portrait'}>
              { node.flipped && text}
              <div className="image-container">
                <Link to={ `blog${node.fields.slug}` }>
                    {node.frontmatter.featuredImage && (
                      <Img
                        fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                        alt={title}
                        className={aspectRatio >= 1 ? 'landscape' : 'portrait'}
                      />
                    )}
                    {!node.frontmatter.featuredImage && (
                      <Img
                        fluid={data.file.childImageSharp.fluid}
                        alt={title}
                        className="card-img"
                      />
                    )}
                  </Link>
                </div>
                { !node.flipped && text }
              </PostContainer>
           )
          })}
        </PostsLayout>
        <ArchiveLinkFooter>
          <Link to='archive'><ArchiveButton>View All Posts</ArchiveButton></Link>
        </ArchiveLinkFooter>
        <BlogFooter group={group} chrissy={data.chrissy}/>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    chrissy: file(relativePath: { eq: "chrissy-portfolio.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 200) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    file(relativePath: { eq: "template.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 1200) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 10
      ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            photoDate(formatString: "MMMM DD, YYYY")
            title
            description
            location
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`